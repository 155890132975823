export const BG_PRIMARY = 'rgba(255,255,255,1)';
export const BG_SECONDARY = 'rgba(147,156,181,1)';
export const BG_ALTERNATIVE = 'rgba(216,225,234,1)';

export const TEXT_PRIMARY = 'rgba(62,62,62,1)';
export const TEXT_SECONDARY = 'rgba(255,255,255,1)';
// export const TEXT_PRIMARY_LIGHT = 'rgba(87,87,87,1)';
export const TEXT_PRIMARY_LIGHT = '#3E3E3E';

export const BTN_NAVIGATION_COLOR = '#5d688b';

// Opacity layer for left content (Desktop view)
export const BG_SECONDARY_TRANSPARENT = '';

export const HEADING_STYLES = {
  color: 'mainColorText',
  fontFamily: 'heading',
  fontSize: '26px',
  letterSpacing: '3px',
  textAlign: 'center',
  textTransform: 'uppercase',
  //paddingLeft: '28px',
};

export const LINK_STYLES = {
  color: 'bgAlternative',
  textDecoration: 'underline',
  fontWeight: 'bold',
  fontStyle: 'italic',
};

export const DEFAULT_BUTTON_PROPS = {};
export const BUTTON_PROPS = {};
